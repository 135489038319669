import React from 'react';
import websites from '../data/websiteData';
import { Popover } from "flowbite-react";
import { useRef } from 'react';
import { useInView } from "framer-motion"

const Websites = ({ setMouseShadowDisabled }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    const handleMouseLeave = () => {
        setMouseShadowDisabled(false);
    };
    const handleMouseEnter = () => {
        setMouseShadowDisabled(true);
    }
    return (
        <div  ref={ref} 
        style={{
           transform: isInView ? "none" : "translateY(-10px)",
           opacity: isInView ? 1 : 0,
           transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) .2s"
         }} 
         
         className='text-center mx-auto section'>
               <h1 
                className='text-center mb-8'>Websites</h1>
            <div className='section flex flex-col w-11/12 lg:grid lg:grid-cols-2 gap-10 mx-auto lg:w-3/4'>
                {websites.map((website) => (
                    <div key={website.name} className='w-auto'>
                        <div className="flex mb-4 items-center justify-between">
                        <h3 className='text-left rounded-lg'>{website.name}</h3>
                            <div className='flex items-center gap-1'>
                                <h4 className='mr-4'>Stack</h4>
                                {website.icons.map((icon, i) => (
                                        <Popover trigger="hover" content={
                                            <div className="text-white bg-[#001321] px-4 py-2">
                                                <div>
                                                    <p key={i} >{icon.title}</p>
                                                </div>
                                            </div>
                                        } placement="top" >
                                            <img
                                              onMouseEnter={handleMouseEnter}
                                              onMouseLeave={handleMouseLeave}
                                            key={i} src={icon.src} alt={icon.alt} className='w-8 bg-[#000000] rounded-full p-1 border-[#0057FF] border-2' />
                                        </Popover>
                                ))}
                            </div>
                        </div>

                        <div className='bg-[#041018]  project p-4 text-left rounded-t-lg' style={{
                            backgroundImage: `url(${website.img})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}>
                        
                        </div>
                        <div className='relative z-[2]'>
                                <div className='bg-[#00101C] p-4 rounded-b-lg mb-20 lg:mb-0'>
                                    <p className='p-2 pb-6 leading-relaxed text-left'>{website.description}</p>
                                    <a target="_blank" href={website.url}>
                                        <button   
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        className='bg-[#ffffff] text-black hover:bg-[#0057FF] hover:text-white rounded-full py-1 px-10 flex mr-auto gap-4 items-center text-lg font-normal md:font-bold'>Website bekijken</button>
                                    </a>
                                </div>
                            </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Websites;
