const websiteData = [
    {
        name: "Foxhole Events",
        alt: "Foxhole Logo",
        description: 'Toen een vriend zijn bedrijf startte, hielp ik hem met een eenvoudige website die vooral de sfeer van de events toont. Voor uitdagingen zoals ticketverkoop gebruikte ik het betaalbare platform WeTicket. Voor een overvolle galerij schakelde ik over naar Amazon Web Services S3 Buckets voor betere schaalbaarheid. Ik koos Elementor als paginabouwer vanwege het gebruiksgemak, zodat mijn vriend zelf aanpassingen kan doen.',
        url:'https://foxhole-events.nl/',
        img:'./img/websites/Foxhole.png',
        icons: [
            { src: "./img/icons/wordpress.svg", alt: "Tailwind Icon", title: "Tailwind css"},
            { src: "./img/icons/elementor.svg", alt: "Elementor Icon",title: "Elementor pagebuilder" }
          ],
    },
    {
        name: "Insumma",
        alt: "Insumma Logo",
        description: 'Tijdens mijn stage bij Ignaz kreeg ik de taak om een nieuwe website voor Insumma te ontwikkelen, op basis van een aangeleverd ontwerp.Voor dit project gebruikte ik WordPress met de Elementor paginabouwer. Dit project leerde me het belang van voldoende tijd inplannen. Ook heb ik vaardigheden opgedaan met "Custom posts", wat ideaal bleek voor dit soort projecten omdat het klanten toelaat om via het dashboard alles te beheren, zoals het aanmaken van blogs voor SEO en nog veel meer',
        url:'https://www.insumma.nl/',
        img:'./img/websites/Insumma.png',
        icons: [
            { src: "./img/icons/wordpress.svg", alt: "Tailwind Icon", title: "Tailwind css"},
            { src: "./img/icons/elementor.svg", alt: "Elementor Icon",title: "Elementor pagebuilder" }
          ],
    },
    {
        name: "Rijschool Yellow",
        alt: "Yellow Logo",
        description: 'Tijdens de overname van Rijschool Yellow door Rijschool Besure zijn er veel problemen ontstaan op hun website. Ik ben gevraagd om deze te repareren en te verbeteren. De site was gebouwd met een verouderde, eigen page builder en custom thema. Dit project heeft me vooral geleerd dat het ook belangrijk is om naar de "longevity" van een website te denken.' ,
        url:'https://rijschoolyellow.nl/',
        img:'./img/websites/Yellow.png',
        icons: [
            { src: "./img/icons/wordpress.svg", alt: "Tailwind Icon", title: "Tailwind CSS"},
          ],
    },
];
export default websiteData;
