import React from 'react'
import { Footer, FooterCopyright, FooterLink, FooterLinkGroup } from "flowbite-react";

const Footerr = ({ setMouseShadowDisabled, mouseShadowDisabled }) => {
  const disableMouseShadow = () => {
    setMouseShadowDisabled(true);
  };

  const enableMouseShadow = () => {
    setMouseShadowDisabled(false);
  };

  return (
    <Footer container className='h-60 md:h-auto' >
      <FooterLinkGroup className='items-center  hidden lg:block gap-10'>
        <div className='flex gap-4'>
          <a href='mailto:denizhrnjic@gmail.com'
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}>
            <img src='../img/icons/gmail.svg' alt='Gmail Icon' className=' hidden md:block rounded-full p-1 w-14 h-14  bg-white' /></a>
          <a target='blank' href='https://www.linkedin.com/in/deniz-hrnjic/'
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}>
            <img src='../img/icons/linkedin.svg' alt='Linkedin Icon' className=' hidden md:block w-14 h-14 rounded-full bg-white' /></a>
          <a target='blank' href="https://github.com/denizzzzzzz"
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}
          ><img src='../img/icons/github2.svg' alt='Github Icon' className=' hidden md:block w-14 h-14 rounded-full p-1  bg-white' /></a>
        </div>
      </FooterLinkGroup>
      <FooterLinkGroup className="text-xl hidden md:flex" >

        <ul className='flex gap-8'>
          <li
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}>
            <a href="/">Over Mij</a></li>
          <li
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}>
            <a href="/projecten">Websites / Projecten</a></li>
        </ul>

      </FooterLinkGroup>
    </Footer>
  )
}

export default Footerr