const projectData = [
  {
    name: 'Video Platform',
    description: 'Een Vue.js applicatie die gebruik maakt van de pexels API om zo videos te laten zien. Met een zoekbalk, detailpagina en soortgelijke videos. Het doel: een basis in Vue js leren.',
    url: 'https://github.com/denizzzzzzz/video-platform',
    img: './img/projects/coming-soon.jpg',
    github: '',
    icons: [
      { src: "./img/icons/vue.svg", alt: "Vue Icon", title: "Vue.js" },
      { src: "./img/icons/tailwind.svg", alt: "Tailwind Icon", title: "Tailwind CSS" },
      { src: "./img/icons/pexels.svg", alt: "Pexels Icon", title: "Pexels video en foto's API" }
    ],
  },
  {
    name: 'Booknote Archive',
    description: 'Een applicatie om je booknotities in te beheren. Met de optie om handgeschreven tekst om te zetten naar tekst! Ik heb geleerd dat het werken met Modals de UX erg kan bevorderen, ook heb ik geleerd met livewire te werken om een sneller CRUD systeem te bouwen. Daarnaast heb ik ontdekt hoe gemakkelijk het is om de Google Cloud Vision API te gebruiken.',
    url: 'https://github.com/denizzzzzzz/booknote-archive',
    img: './img/projects/booknote-archive.png',
    github: '',
    icons: [
      { src: "./img/icons/laravel.svg", alt: "Laravel Icon", title: "Laravel" },
      { src: "./img/icons/tailwind.svg", alt: "Tailwind Icon", title: "Tailwind CSS" },
      { src: "./img/icons/livewire.svg", alt: "Livewire Icon", title: "Livewire" },
      { src: "./img/icons/sql.svg", alt: "SQL Icon", title: "SQL Database" }
    ],
  },
  {
    name: 'Quizerr',
    description: 'Een React-Laravel Applicatie waar de docent vragen kan uploaden als een csv bestand (excel) om zo een quiz te maken voor de leerlingen. Ik heb geleerd hoe ik in Laravel mijn eigen API kan maken om zo mijn de geuploade vragen op te halen op de frontend met React.',
    url: 'https://github.com/denizzzzzzz/Quizerr',
    img: './img/projects/quizerr.png',
    github: '',
    icons: [
      { src: "./img/icons/laravel.svg", alt: "Laravel Icon", title: "Laravel" },
    { src: "./img/icons/react.svg", alt: "React Icon", title: "React" },
    { src: "./img/icons/tailwind.svg", alt: "Tailwind Icon", title: "Tailwind CSS" },
    { src: "./img/icons/sql.svg", alt: "SQL Icon", title: "SQL Database" }
    ],
  },
]
export default projectData;