import React, { useState, useEffect } from 'react';

const MouseShadow = ({ isDisabled, scrollInstance }) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        const handleMouseMove = (e) => {
            const scrollY = scrollInstance ? scrollInstance.scroll.instance.scroll.y : 0;
            setPosition({ x: e.clientX, y: e.clientY + scrollY });
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [scrollInstance]);

    const shadowStyle = {
        position: 'fixed',
        left: `${position.x}px`,
        top: `${position.y}px`,
        scale: isDisabled ? '.8' : '1',
        background: isDisabled ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.5)',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        transition: 'background, scale 0.1s ease',
    };

    const cursorStyle = {
        position: 'absolute',
        left: '60%',
        top: '88%',
        transform: 'translate(-50%, -50%)',
        pointerEvents: 'none', 
    };

    if (isMobile) {
        return null;  
    }

    return (
        <div className="mouse-shadow" style={shadowStyle}>
            {isDisabled && <img className="cursor" style={cursorStyle} src='./img/icons/focus-icon.svg' alt="icon" />}
        </div>
    );
};

export default MouseShadow;
