import { useRef, useEffect } from "react";
import gsap from "gsap";

const Top = ({ setMouseShadowDisabled }) => {
    const h1Ref = useRef(null);
    const h2Ref = useRef(null);
    const buttonRef = useRef(null);
    const mouseRef = useRef(null);
    const imageRef = useRef(null);

    const disableMouseShadow = () => {
        setMouseShadowDisabled(true);
    };

    const enableMouseShadow = () => {
        setMouseShadowDisabled(false);
    };
    useEffect(() => {
        const tl = gsap.timeline();
        const text = h1Ref.current.textContent;
        h1Ref.current.textContent = '';
        const typingTl = gsap.timeline();
    
        text.split('').forEach((char, index) => {
            const span = document.createElement('span');
            span.textContent = char;
            span.style.opacity = 0;
            h1Ref.current.appendChild(span);
    
            typingTl.to(span, {
                opacity: 1,
                duration: .1,
                ease: 'power3.out',
                delay: index * 0.1,
            }, 0);
        });
    
        tl.fromTo(imageRef.current, { opacity: 0, y: 0 }, { opacity: 0.8, y: 0, duration: 1, ease: "power3.out" })
          .add(typingTl) 
          .fromTo(h2Ref.current, { opacity: 0, y: 0 }, { opacity: 0.8, y: 0, duration: .5, ease: "power3.out" }, "+=0.4")
          .fromTo(buttonRef.current, { opacity: 0, y: 40 }, { opacity: 1, y: 0, duration: 0.2, ease: "power3.out" })
          .fromTo(mouseRef.current, { opacity: 0, y: 20 }, { opacity: 0.8, y: 0, duration: 2, ease: "power3.out", yoyo: true, repeat: -1 }, "+=0.6");
    }, []);

    return (
        <div className="section pt-32 md:pt-60  text-center max-w-sm mx-auto  md:max-w-2xl lg:text-left lg:max-w-4xl lg:mx-auto xl:max-w-6xl 2xl:max-w-screen-xl">
            <div className='flex flex-col-reverse items-center justify-between lg:flex-row'>
                <div className="mb-2-">
                    <h1 className="text-4xl md:text-7xl mb-4" ref={h1Ref}>Deniz Hrnjic</h1>
                    <h2 ref={h2Ref} className='mb-14 lg:mb-10 text-2xl md:text-4xl '>Junior Frontend Developer.</h2>
                    <a href="#about" id="about_link"><button ref={buttonRef}  onMouseEnter={disableMouseShadow}
                        onMouseLeave={enableMouseShadow}  id="about_button" class="hidden md:block">Leer mij kennen</button></a>
                </div>
                <img
                ref={imageRef}
                    src="../img/general/deniz.png"
                    className=' w-2/4 lg:w-1/3 mb-10 md:mb-0'
                    alt='Deniz'
                />
            </div>
            <img
                ref={mouseRef}
                src='../img/general/mouse-scroll.svg'
                className='w-14 text-center mt-4 mx-auto'
                alt='Keep Scrolling'
            />
        </div>
    );
};

export default Top;
