const softwareSkills = [
    {
        name: 'PHP',
        imageSrc: './img/icons/php.svg',
        imageAlt: 'PHP Logo',
        rating: 4,
        description: 'Ik kan goed overweg met php zowel binnen wordpress of laravel als daarbuiten met het maken van custom plugins bijvoorbeeld.'
    },
    {
        name: 'MySQL',
        imageSrc: './img/icons/sql.svg',
        imageAlt: 'MySQL Logo',
        rating: 4,
        description: 'Ik ben in staat allerlij soorten queries te schrijven meestal gebruik ik hiervoor het Eloquent ORM om zo te zorgen dat de snelheid en schaalbaarheid word waargeborgd'
    },
    {
        name: 'Laravel',
        imageSrc: './img/icons/laravel.svg',
        imageAlt: 'Laravel Logo',
        rating: 4.5,
        description: 'Tijdens mijn opleiding heb ik hier mee leren werken en ik heb het framework goed onder de knie.'
    },
    {
        name: 'Livewire',
        imageSrc: './img/icons/livewire.svg',
        imageAlt: 'Livewire Logo',
        rating: 4,
        description: 'Met livewire kan ik echt tegekke dingen maken binnen laravel.'
    },
    {
        name: 'WordPress',
        imageSrc: './img/icons/wordpress.svg',
        imageAlt: 'WordPress Logo',
        rating: 3.5,
        description: 'Voor verschillende cases heb ik wordpress gebruikt en soms een kleine custom plugin gebouwd.'
    },
    {
        name: 'JavaScript',
        imageSrc: './img/icons/js.svg',
        imageAlt: 'JavaScript Logo',
        rating: 3,
        description: 'Ik kan wel wat plain javascript schrijven maar niet heel veel, genoeg om coole effecten te maken of dingen tijdelijk op te slaan.'
    },
    {
        name: 'React',
        imageSrc: './img/icons/react.svg',
        imageAlt: 'React Logo',
        rating: 3.5,
        description: 'React is wel een van mijn favorieten frameworks ik werk er graag mee, deze website is ook helemaal in React gemaakt. Ik ken een goede basis React.'
    },
    {
        name: 'Tailwind CSS',
        imageSrc: './img/icons/tailwind.svg',
        imageAlt: 'Tailwind CSS Logo',
        rating: 5,
        description: 'Meestal als ik code typ gebruik ik tailwind, vind het zelf heel fijn en gemakkelijk te gebruiken.'
    },
    {
        name: 'CSS',
        imageSrc: './img/icons/css.svg',
        imageAlt: 'CSS Logo',
        rating: 5,
        description: 'Ja, ik denk dat iedereen wel css kan leren ik heb dit onder de knie.'
    },
    {
        name: 'GitHub',
        imageSrc: './img/icons/github.svg',
        imageAlt: 'GitHub Logo',
        rating: 4,
        description: 'Github desktop gebruik ik vaak voor mijn versiebeheer, ik weet ook hoe je met branches werkt, issues maakt, merge conflicten oplost etc.'
    },
    {
        name: 'Sharp',
        imageSrc: './img/icons/c-sharp.svg',
        imageAlt: 'Sharp Logo',
        rating: 3,
        description: 'Ik heb een basis kennis aan C# maar ben hier tegenwoordig niet meer zoveel mee bezig aangezien ik mezelf echt op het web wil focussen.'
    },
    {
        name: 'API',
        imageSrc: './img/icons/api.svg',
        imageAlt: 'API Logo',
        rating: 4,
        description: 'Ik kan allerlij soorten APIs gebruiken, heb laast bijvoorbeeld een Google Cloud Vision API gebruikt om geschreven tekst om te zetten naar een object. Ook weet ik hoe ik een api zelf kan maken.'
    },
    {
        name: 'Vue',
        imageSrc: './img/icons/vue.svg',
        imageAlt: 'API Logo',
        rating: 2,
        description: 'Ik ben mezelf nu een beetje aan het verdiepen in Vue.js. Het is wel makkelijk te begrijpen.'
    }
];

export default softwareSkills;
