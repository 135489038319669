import React, { useState, forwardRef } from 'react';
import emailjs from 'emailjs-com';

const ContactPopup = forwardRef((props, ref) => {
  const { setMouseShadowDisabled } = props;
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({ email: '', subject: '', message: '' });

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const validateInput = () => {
    let tempErrors = { email: '', subject: '', message: '' };
    let isValid = true;

    if (!email) {
      tempErrors.email = 'Email is verplicht';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Gebruik een bestaand email';
      isValid = false;
    }

    if (!subject) {
      tempErrors.subject = 'Onderwerp is Verplicht';
      isValid = false;
    }

    if (!message) {
      tempErrors.message = 'Bericht is verplicht';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  const handleMouseLeave = () => {
    setMouseShadowDisabled(false);
  };
  const handleMouseEnter = () => {
    setMouseShadowDisabled(true);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    const templateParams = {
      from_email: email,
      to_name: 'Deniz',
      subject: subject,
      message: message,
    };

    emailjs.send('service_xzklubt', 'template_pizbr14', templateParams, 'Gqzq_vYNePcVOu-mk')
      .then(response => {
        console.log('SUCCESS!', response.status, response.text);
        setFormSubmitted(true);
      }, err => {
        console.log('FAILED...', err);
      });
  };

  return (
    <div ref={ref} className={`contact-popup ${props.show ? 'show' : ''} bg-[#0057FF] bg-opacity-95 p-4 md:p-8 flex items-center justify-center z-[100]`}>
      <div className="w-full max-w-md">
        {formSubmitted ? (
          <div className="text-center">
            <h2 className="font-bold mb-4">Bedankt voor je bericht!</h2>
            <p className='mb-10'>Ik neem zo snel mogelijk contact met u op.</p>
            <img className='rounded-lg mx-auto w-full' src='./img/general/message-succes.gif' />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <h2 className='font-400 mb-4'>Kom in contact</h2>
            <div>
              <div className='mb-4'>
                <h4>Email</h4>
                <input  
                 onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                                        type="email" value={email} onChange={handleInputChange(setEmail)} placeholder='email@voorbeeld.nl' className="bg-[#00101C] p-2 form-input mt-1 block w-full shadow-sm" />
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
              </div>
              <div className='mb-4'>
                <h4>Onderwerp</h4>
                <input             
                
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}

                type="text" value={subject} onChange={handleInputChange(setSubject)} placeholder='Ik wil je aannemen!' className="bg-[#00101C] p-2 form-input mt-1 block w-full shadow-sm" />
                {errors.subject && <p className="text-red-500 text-xs mt-1">{errors.subject}</p>}
              </div>
              <div className='mb-2'>
                <h4>Bericht</h4>
                <textarea              
                
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                
                rows="3" value={message} onChange={handleInputChange(setMessage)} placeholder='Hier kan een kort verhaal....' className="bg-[#00101C] p-2 form-textarea mt-1 block w-full shadow-sm"></textarea>
                {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
              </div>
              <button              
              onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                type="submit" className='mt-6 bg-white hover:bg-[#0057FF] hover:text-white text-black px-0 py-1 md:px-6 md:py-2 rounded-full text-lg w-full font-normal md:font-bold'>Verzenden</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
});

export default ContactPopup;
