import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import ContactPopup from './ContactPopup';
import { useContact } from '../context/ContactContext';

const ContactCircle = ({ setMouseShadowDisabled }) => {
  const { showPopup, togglePopup } = useContact(); // Use context values
  const popupRef = useRef(null);
  const circleRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);



  const currentIcon = showPopup ? faClose : isHovered ? faEnvelopeOpen : faEnvelope;

 


  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const tl = gsap.timeline({
      defaults: { ease: "power3.out" },
    });

    if (showPopup) {
      const xValue = isMobile ? 26 : 30;
      const yValue = isMobile ? -500 : -460;
      const scaleValue = isMobile ? 0.50 : 0.5;

      tl.to(circleRef.current, {
        duration: 0.2,
        x: xValue,
        y: yValue,
        scale: scaleValue,
        rotate: 360*2
      })
      .to(popupRef.current, {
        duration: 0.6,
        opacity: 1,
        x: isMobile ? -22 : -60,
        y: isMobile ? -50 : -40,
        display: 'block',
      }, "<")
    } else {
      tl.to(circleRef.current, {
        duration: 0.1,
        x: 0,
        y: 0,
        scale: 1,
        rotate: 0
    
      })
      .to(popupRef.current, {
        duration: 0.2,
        x: 300,
        onComplete: () => {
          gsap.set(popupRef.current, { display: 'none' });
        }
      });
    }
  }, [showPopup]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setMouseShadowDisabled(true);
  };
  
  const handleMouseLeave = () => {
    setIsHovered(false);
    setMouseShadowDisabled(false);
  };


  return (
<div>
<div
  ref={circleRef}
  className={`contact_circle ${showPopup ? 'active' : ''}`}
  onClick={togglePopup}
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
  
  
>
  <FontAwesomeIcon icon={currentIcon} size='2x'/>
</div>

      <ContactPopup  setMouseShadowDisabled={setMouseShadowDisabled}  show={showPopup} ref={popupRef} />
    </div>
  );
};

export default ContactCircle;
