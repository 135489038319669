import React, { useState, useEffect } from 'react';
import { useContact } from '../context/ContactContext';

const Header = ({ setMouseShadowDisabled, mouseShadowDisabled }) => {
  const { togglePopup } = useContact();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);


  const disableMouseShadow = () => {
    setMouseShadowDisabled(true);
  };

  const enableMouseShadow = () => {
    setMouseShadowDisabled(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    document.querySelector('.header').style.backgroundPosition = '0 0';
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsScrolled(offset > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <div className='flex items-center px-6 py-6 md:py-4 md:px-10'>
        <a href='/'> <img src='../img/general/deniz.png'  alt='Profile' 
           onMouseEnter={disableMouseShadow}
           onMouseLeave={enableMouseShadow}
        className='h-10 w-10 lg:w-14 lg:h-14 rounded-full border-2 bg-[#001524]' /></a>
        <div className='ml-auto mr-12 hidden lg:flex gap-14 text-xl list-none'>
          <li
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}>
            <a href="/">Over Mij</a></li>
          <li
            onMouseEnter={disableMouseShadow}
            onMouseLeave={enableMouseShadow}>
            <a href="/projecten">Websites / Projecten</a></li>
        </div>
        <button
          id="contact_button"
          onMouseEnter={disableMouseShadow}
          onMouseLeave={enableMouseShadow}
          onClick={togglePopup}
          className=' text-white hidden lg:block'>
          Contact
        </button>
        <button className="lg:hidden ml-auto" onClick={toggleMobileMenu}>
          <svg
            className={`w-6 h-6 transition-all duration-300`}
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            stroke="currentColor"
          >
            {isMobileMenuOpen ? (
              <>
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </>
            ) : (
              <>
                <line x1="4" y1="6" x2="20" y2="6" />
                <line x1="4" y1="12" x2="20" y2="12" />
                <line x1="4" y1="18" x2="20" y2="18" />
              </>
            )}
          </svg>
        </button>
      </div>
      {isMobileMenuOpen && (
        <ul className={`flex flex-col items-center gap-4 text-xl shadow-md py-4 list-none ${isScrolled ? 'scrolled-background' : ''}`}>
          <li
          ><a href="/">Over Mij</a></li>
          <li><a href="/projecten">Websites / Projecten</a></li>
          <div className='flex gap-4 mt-10'>
            <a href='mailto:denizhrnjic@gmail.com'> <img src='../img/icons/gmail.svg' alt='Gmail Icon' className='rounded-full p-1 w-10 h-10  bg-white' /></a>
            <a target='blank' href='https://www.linkedin.com/in/deniz-hrnjic/'><img src='../img/icons/linkedin.svg' alt='Linkedin Icon' className='w-10 h-10 rounded-full bg-white' /></a>
            <a target='blank' href="https://github.com/denizzzzzzz" ><img src='../img/icons/github2.svg' alt='Github Icon' className=' w-10 h-10 rounded-full p-1  bg-white' /></a>
          </div>
        </ul>

      )}
      <div className={`border-b-2 border-[#0057FF] ${isScrolled ? '' : 'mx-10'} opacity-50 rounded-full border-transition`}></div>
    </div>
  );
};

export default Header;
