import './App.css';
import ContactCircle from './components/ContactCircle';
import ContactPopup from './components/ContactPopup';
import Footerr from './components/Footerr';
import Header from './components/Header';
import { ContactProvider } from './context/ContactContext';
import MouseShadow from './functions/MouseShadow';
import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Projects from './pages/Projects';
import Home from './pages/Home';
import PageNotFound from './pages/PageNotFound';

function App() {

  const [mouseShadowDisabled, setMouseShadowDisabled] = useState(false);

  return (
    <BrowserRouter>
      <ContactProvider>
        <Header setMouseShadowDisabled={setMouseShadowDisabled} />
        <Routes>
          <Route path="/" element={<Home setMouseShadowDisabled={setMouseShadowDisabled}  />} />
          <Route path="/projecten" element={<Projects setMouseShadowDisabled={setMouseShadowDisabled}  />} />
          <Route path="*" element={<PageNotFound isDisabled={mouseShadowDisabled}  />} />
        </Routes>
        <ContactPopup setMouseShadowDisabled={setMouseShadowDisabled} />
        <ContactCircle setMouseShadowDisabled={setMouseShadowDisabled} />
        <Footerr setMouseShadowDisabled={setMouseShadowDisabled} />
        <MouseShadow isDisabled={mouseShadowDisabled} />
      </ContactProvider>
    </BrowserRouter>
  );
}

export default App;
