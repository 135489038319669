import  { useRef } from 'react';
import { Avatar, Blockquote, Carousel } from "flowbite-react";
import { useInView } from "framer-motion"

const Testimonials = () => {
   
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <div  ref={ref} 
        style={{
           transform: isInView ? "none" : "translateY(-10px)",
           opacity: isInView ? 1 : 0,
           transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) .2s"
         }}
         
         className="section text-left  md:w-10/12 lg:mx-auto"> 
            <h1 className='text-center text-3xl mb-4 md:mb-0'>Wat andere over mij zeggen</h1>
            <div className='flex flex-wrap hidden md:block w-full gap-12 testimonial-slider '>
                <Carousel slideInterval={5000} indicatorClasses="hidden" className=' px-12' >
                    <figure className="mx-auto max-w-screen-md text-center">
                        <svg
                            className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 14"
                        >
                            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                        </svg>
                        <Blockquote>
                            <p className="font-medium italic text-gray-900  dark:text-white">
                                "De afgelopen jaren heb ik Deniz op school leren kennen als een bijzonder gedreven student. Typisch iemand die niet zomaar tevreden is met een zesje. Deniz gaat vaak net een stapje verder dan de rest, en zijn interesse stopt niet bij de dingen we op school onderwijzen.
                                Die nieuwsgierigheid en gedrevenheid zijn ontzettend belangrijk om een uitstekende developer te worden. Ik durf dan ook te zeggen dat Deniz nu al een goede student is, en dat hij met zekerheid zal doorgroeien tot een erg waardevolle junior developer.
                                Wat daar nog aan bijdraagt, is ook zijn brede interesse. Deniz is een slimme jongen met een brede basiskennis, juist niet alleen over programmeren. Dat maakt dat hij goed is in het overzien van het werk, het doorgronden van problemen en het zoeken van een geschikte aanpak.
                                Geef mij maar een hele klas vol van zulke studenten!"
                            </p>
                        </Blockquote>
                        <figcaption className="mt-6 flex items-center justify-center space-x-3">
                            <Avatar rounded size="md" img="../img/general/bart-roos.jpg" alt="profile picture" />
                            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                <cite className="pr-3 font-medium text-gray-900 dark:text-white">Bart Roos</cite>
                                <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Docent</cite>
                            </div>
                        </figcaption>
                    </figure>

                    <figure className="mx-auto max-w-screen-md text-center">
                        <svg
                            className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 14"
                        >
                            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                        </svg>
                        <Blockquote>
                            <p className="font-medium italic  text-gray-900 dark:text-white">
                                "Ik en deniz kennen elkaar al een langere tijd, toen ik mijn eigen bedrijf begon was deniz de eerste die zijn hulp aanbod om
                                een site te maken, ik had wensen en ideeen maar deniz nog niet de ervaring om dat te kunnen maken,
                                Maar dat maakte deniz niet uit hij heeft zijn volle 100% gegeven en de website heeft al mijn verwachtingen overtroffen.
                                Daarnaast hebben we ondanks onze vriendschap het erg formeel en zakelijk gehouden. De samenwerking was erg sterk, deniz
                                communiceerde duidelijk, luisterde goed naar mijn vragen, heeft uitgebrijdt mijn visie uitgevraagd en mij frequent updates
                                en prototypes laten zien om naar mijn mening te vragen. daarnaast kwam hij zelf ook nog met sterke ideeen waar ik zelf niet
                                op was gekomen waardoor de site er nog beter uitzag.
                                Ik ken weinig mensen met zoveel doorzettings vermogen en creativiteit, die passie hebben voor hun vak en niet
                                genoegen nemen met een goed product maar voor het beste streven."
                            </p>
                        </Blockquote>
                        <figcaption className="mt-6 flex items-center justify-center space-x-3">
                            <Avatar rounded size="md" img="../img/general/paul-de-vos.jpg" alt="profile picture" />
                            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                <cite className="pr-3 font-medium text-gray-900 dark:text-white">Paul De Vos</cite>
                                <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Eigenaar Foxhole Events</cite>
                            </div>
                        </figcaption>
                    </figure>


                    <figure className="mx-auto  max-w-screen-md text-center">
                        <svg
                            className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 18 14"
                        >
                            <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                        </svg>
                        <Blockquote>
                            <p className="font-medium italic text-gray-900 dark:text-white">
                                "Deniz' toewijding en discipline zijn werkelijk uitzonderlijk. Ik heb met Deniz samengewerkt aan verschillende projecten.
                                Zijn vermogen om nieuwe vaardigheden te leren en deze toe te passen, is niets minder dan indrukwekkend.
                                Tijdens een project waarbij wij geconfronteerd werden met de uitdaging om een animatievideo te maken,
                                koos ik, uit gemak, voor het idee om een externe editor in te huren. Deniz, echter, zag dit als een uitgelezen kans om zichzelf te ontwikkelen.
                                Met een ongekende toewijding nam hij twee volledige dagen de tijd om Adobe's After Effects programma eigen te maken. Het resultaat?
                                Een buitengewoon mooie animatievideo die niet alleen onze verwachtingen overtrof, maar ook een bewijs was van zijn vastberadenheid en leergierigheid.
                                Maar het stopte niet bij videobewerking. Toen wij een website moesten bouwen, stond Deniz erop om niet de gemakkelijke weg te kiezen door een webdeveloper
                                in te huren. In plaats daarvan koos hij ervoor om de uitdaging aan te gaan en de website zelf te bouwen.

                            </p>
                        </Blockquote>
                        <figcaption className="mt-6 flex items-center justify-center space-x-3">
                            <Avatar rounded size="md" img="../img/general/canal-doksoz.jpg" alt="profile picture" />
                            <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                                <cite className="pr-3 font-medium text-gray-900 dark:text-white">Canali Doksöz</cite>
                                <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Goede vriend</cite>
                            </div>
                        </figcaption>
                    </figure>
                </Carousel>
            </div>
            <div className='block md:hidden'>
                <figure className="mx-auto max-w-screen-md p-4 mb-14 text-center">
                    <svg
                        className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 14"
                    >
                        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                    </svg>
                    <Blockquote>
                        <p className=" italic text-gray-900 text-left dark:text-white">
                            "De afgelopen jaren heb ik Deniz op school leren kennen als een bijzonder gedreven student. Typisch iemand die niet zomaar tevreden is met een zesje. Deniz gaat vaak net een stapje verder dan de rest, en zijn interesse stopt niet bij de dingen we op school onderwijzen.
                            Die nieuwsgierigheid en gedrevenheid zijn ontzettend belangrijk om een uitstekende developer te worden. Ik durf dan ook te zeggen dat Deniz nu al een goede student is, en dat hij met zekerheid zal doorgroeien tot een erg waardevolle junior developer.
                            Wat daar nog aan bijdraagt, is ook zijn brede interesse. Deniz is een slimme jongen met een brede basiskennis, juist niet alleen over programmeren. Dat maakt dat hij goed is in het overzien van het werk, het doorgronden van problemen en het zoeken van een geschikte aanpak.
                            Geef mij maar een hele klas vol van zulke studenten!"
                        </p>
                    </Blockquote>
                    <figcaption className="mt-6 flex items-center justify-center space-x-3">
                        <Avatar rounded size="md" img="../img/general/bart-roos.jpg" alt="profile picture" />
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <cite className="pr-3 font-medium text-gray-900 dark:text-white">Bart Roos</cite>
                            <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Docent</cite>
                        </div>
                    </figcaption>
                </figure>

                <figure className="mx-auto  p-4 mb-14 max-w-screen-md text-center">
                    <svg
                        className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 14"
                    >
                        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                    </svg>
                    <Blockquote>
                        <p className="italic  text-left text-gray-900 dark:text-white">
                            "Ik en deniz kennen elkaar al een langere tijd, toen ik mijn eigen bedrijf begon was deniz de eerste die zijn hulp aanbod om
                            een site te maken, ik had wensen en ideeen maar deniz nog niet de ervaring om dat te kunnen maken,
                            Maar dat maakte deniz niet uit hij heeft zijn volle 100% gegeven en de website heeft al mijn verwachtingen overtroffen.
                            Daarnaast hebben we ondanks onze vriendschap het erg formeel en zakelijk gehouden. De samenwerking was erg sterk, deniz
                            communiceerde duidelijk, luisterde goed naar mijn vragen, heeft uitgebrijdt mijn visie uitgevraagd en mij frequent updates
                            en prototypes laten zien om naar mijn mening te vragen. daarnaast kwam hij zelf ook nog met sterke ideeen waar ik zelf niet
                            op was gekomen waardoor de site er nog beter uitzag.
                            Ik ken weinig mensen met zoveel doorzettings vermogen en creativiteit, die passie hebben voor hun vak en niet
                            genoegen nemen met een goed product maar voor het beste streven."
                        </p>
                    </Blockquote>
                    <figcaption className="mt-6 flex items-center justify-center space-x-3">
                        <Avatar rounded size="md" img="../img/general/paul-de-vos.jpg" alt="profile picture" />
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <cite className="pr-3 font-medium text-gray-900 dark:text-white">Paul De Vos</cite>
                            <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Eigenaar Foxhole Events</cite>
                        </div>
                    </figcaption>
                </figure>


                <figure className="mx-auto p-4 text-center">
                    <svg
                        className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 18 14"
                    >
                        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                    </svg>
                    <Blockquote>
                        <p className="italic text-left text-gray-900  dark:text-white">
                            "Deniz' toewijding en discipline zijn werkelijk uitzonderlijk. Ik heb met Deniz samengewerkt aan verschillende projecten.
                            Zijn vermogen om nieuwe vaardigheden te leren en deze toe te passen, is niets minder dan indrukwekkend.
                            Tijdens een project waarbij wij geconfronteerd werden met de uitdaging om een animatievideo te maken,
                            koos ik, uit gemak, voor het idee om een externe editor in te huren. Deniz, echter, zag dit als een uitgelezen kans om zichzelf te ontwikkelen.
                            Met een ongekende toewijding nam hij twee volledige dagen de tijd om Adobe's After Effects programma eigen te maken. Het resultaat?
                            Een buitengewoon mooie animatievideo die niet alleen onze verwachtingen overtrof, maar ook een bewijs was van zijn vastberadenheid en leergierigheid.
                            Maar het stopte niet bij videobewerking. Toen wij een website moesten bouwen, stond Deniz erop om niet de gemakkelijke weg te kiezen door een webdeveloper
                            in te huren. In plaats daarvan koos hij ervoor om de uitdaging aan te gaan en de website zelf te bouwen.

                        </p>
                    </Blockquote>
                    <figcaption className="mt-6 flex items-center justify-center space-x-3">
                        <Avatar rounded size="md" img="../img/general/canal-doksoz.jpg" alt="profile picture" />
                        <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                            <cite className="pr-3 font-medium text-gray-900 dark:text-white">Canali Doksöz</cite>
                            <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">Goede vriend</cite>
                        </div>
                    </figcaption>
                </figure>

            </div>
        </div>
    )
}

export default Testimonials