import React from 'react';

const PageNotFound = () => {
    return (
        <div className="py-40 flex flex-col items-center justify-center">
            <img className=" w-10/12 md:w-1/3" src="./img/general/404.jpg" alt="404 image" />
            <h1 className="text-4xl md:text-6xl text-center mt-10 mb-16">
                <strong>404,</strong> Er ging iets mis..
            </h1>
            <a href='/'><button id='about_button'>Breng me naar home</button></a>
        </div>
    );
};

export default PageNotFound;
