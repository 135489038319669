import React, { createContext, useContext, useState } from 'react';

const ContactContext = createContext(); 

export const useContact = () => useContext(ContactContext); 

export const ContactProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <ContactContext.Provider value={{ showPopup, togglePopup }}>
      {children}
    </ContactContext.Provider>
  );
};
