import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileArrowDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import softwareSkills from '../data/softwareSkillsData';
import skills from '../data/skillsData';
import { Popover } from "flowbite-react";
import { motion } from "framer-motion";
import { useInView } from "framer-motion"


const About = ({ setMouseShadowDisabled }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const [downloaded, setDownloaded] = useState(false);
    const [downloadIcon, setDownloadIcon] = useState(faFileArrowDown);
    const buttonRef = useRef(null);
    const PDF_FILE_URL = 'https://portfolio-deniz.nl/deniz-cv.pdf'

    const downloadFileAtURL = () => {
        if (!downloaded) {
            const fileName = PDF_FILE_URL.split('/').pop();
            const aTag = document.createElement('a');
            aTag.href = PDF_FILE_URL;
            aTag.setAttribute('download', fileName);
            document.body.appendChild(aTag);
            aTag.click();
            aTag.remove();

            setDownloaded(true);
            setDownloadIcon(faCheck);
        }
    };
    const handleMouseLeave = () => {
        setMouseShadowDisabled(false);
    };
    const handleMouseEnter = () => {
        setMouseShadowDisabled(true);
    }

    const renderStars = (rating) => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < Math.floor(rating)) {
                stars.push(<img key={i} src='./img/icons/star.svg' className="w-6" alt="Star" />);
            } else if (i === Math.floor(rating) && rating % 1 !== 0) {
                stars.push(<img key={i} src='./img/icons/star-half.svg' className="w-6" alt="Half Star" />);
            } else {
                stars.push(<img key={i} src='./img/icons/star-open.svg' className="w-6" alt="Empty Star" />);
            }
        }
        return stars;
    };


    return (
        <div id="about"
            ref={ref}
            style={{
                transform: isInView ? "none" : "translateY(-10px)",
                opacity: isInView ? 1 : 0,
                transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) .2s"
            }}

            className="section text-left px-4 mx-auto md:max-w-2xl lg:text-center lg:max-w-4xl lg:mx-auto xl:max-w-6xl 2xl:max-w-screen-xl">
            <motion.h1 animate={{ y: -10, scale: 1 }} initial={{ scale: 0 }} className='hidden md:block'>Over mij</motion.h1>
            <h2 className='mb-8 hidden md:block lg:mb-20'>Hover of klik de icoontjes om meer te lezen</h2>
            <div className='flex gap-20 flex-col-reverse lg:gap-0 lg:flex-row xl:gap-48 mb-32'>
                <div className='text-left'>
                    <h3 className='mb-4 text-[#ffffffdc]'>Mijn software skills</h3>
                    <div className='flex flex-wrap gap-2 mb-6'>
                        {softwareSkills.map((skill) => (
                            <div key={skill.name} className="skill-icon">
                                <Popover trigger="hover" content={
                                    <div className="w-80  text-white bg-[#001321] px-4 py-2">
                                        <div>
                                            <h3>{skill.name}</h3>
                                        </div>
                                        <div className="px-3 py-2">
                                            <div className='stars flex flex-row gap-4 mb-4'>
                                                {renderStars(skill.rating)}
                                            </div>
                                            <p>{skill.description}</p>
                                        </div>
                                    </div>
                                } placement="top" >
                                    <img
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}

                                        src={skill.imageSrc} alt={skill.imageAlt} className='w-12 p-2 bg-[#00000057] border-[#0057FF] border-2 rounded-full' />
                                </Popover>
                            </div>
                        ))}
                    </div>
                    <h3 className='mb-4'>Andere skills</h3>
                    <div className='flex flex-wrap gap-2 mb-20 md:mb-6'>
                        {skills.map((skill) => (
                            <div key={skill.name} className="skill-icon">
                                <Popover trigger="hover" content={
                                    <div className="w-80  text-white bg-[#001321] px-4 py-2">
                                        <div>
                                            <h3>{skill.name}</h3>
                                        </div>
                                        <div className="px-3 py-2">
                                            <div className='stars flex flex-row gap-4 mb-4'>
                                                {renderStars(skill.rating)}
                                            </div>
                                            <p>{skill.description}</p>
                                        </div>
                                    </div>
                                } placement="top" >
                                    <img src={skill.imageSrc}

                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        alt={skill.imageAlt} className='w-12 p-2 bg-[#00000057] border-[#0057FF] border-2 rounded-full' />
                                </Popover>
                            </div>
                        ))}
                    </div>
                    <h3 className='mb-4'>Ervaring</h3>
                    <div className=' experience flex flex-row gap-6   border-white'>
                        <FontAwesomeIcon icon={faCalendar} size='2x' className='text-white ' />
                        <div>
                            <h4 >Freelance Web Developer en Creatief Designer -
                                Perfectivity </h4>
                            <h5 className='opacity-80'>Januari 2023 - Heden</h5>
                        </div>
                    </div>
                    <div className='line'>
                    </div>
                    <div className=' experience flex flex-row gap-6 mb-14'>
                        <FontAwesomeIcon icon={faCalendar} size='2x' className='text-white' />
                        <div>
                            <h4>Software Developer Stagier - Ignaz Software</h4>
                            <h5 className='opacity-80'>Februaru 2023 - Juli 2023</h5>
                        </div>
                    </div>
                    <button
                        ref={buttonRef}
                        id='contact_button'
                        onClick={downloadFileAtURL}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        className={`bg-[#0057FF] text-white rounded-full font-Archivo font-thin ${downloaded ? 'active' : ''}`}
                    >
                        <span className='text-lg' id="contact_button_text">{downloaded ? "CV Gedownload" : "Volledig CV Downloaden"}</span>
                        <FontAwesomeIcon icon={downloadIcon} className='pl-2 md:pl-6' />
                    </button>
                </div>
                <div className='description text-left w-full md:w-full lg:w-1/3 '>
                    <img src='../img/general/me.jpeg' className='rounded-lg h-60 object-cover md:h-auto mb-6 w-full' />
                    <h1 className='text-xl  block md:hidden'>Over mij</h1>
                    <p className='text-left'>

                        Ik ben Deniz ik ben 21 Jaar oud en woon in Breda mijn vrienden zouden mij omschrijven als: hardwerkend, leergierig en creatief
                        ik hou van sporten, nieuwe dingen proberen, boeken lezen en met vrienden leuke dingen doen.
                        Op dit moment heb ik met succes mijn opleiding Software Developer afgerond het enige wat mij nog te doen staat is aan afstudeerstage.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default About