const skills = [
    {
        name: 'Adobe Premiere Pro',
        imageSrc: './img/icons/premiere.svg',
        imageAlt: 'Adobe Premiere Pro Logo',
        rating: 3,
        description: 'In mijn vrije tijd wil ik weleens videos bewerken dan gebruik ik premiere pro ik vind het leuk om videos te bewerken.'
    },
    {
        name: 'Adobe Illustrator',
        imageSrc: './img/icons/illustrator.svg',
        imageAlt: 'Adobe Illustrator  Logo',
        rating: 4,
        description: 'Illustrator gebruik ik best vaak als ik logos wil aanpassen of maken en om allerlij andere soorten vectors te maken.'
    },
    {
        name: 'Adobe Photoshop',
        imageSrc: './img/icons/photoshop.svg',
        imageAlt: 'Adobe Photoshop Logo',
        rating: 2,
        description: 'Laatste tijd gebruik ik niet zo vaak meer photoshop maar ik kan er wel mee overweg als het nodig is.'
    },
    {
        name: 'Figma',
        imageSrc: './img/icons/figma.svg',
        imageAlt: 'Figma Logo',
        rating: 4,
        description: 'Hierin maak ik altijd mijn web designs het werkt echt makkelijk, ik kan ook gelijk de flow van de applicatie erin zetten wat het handig maakt om een proof of concept te demonstreren.'
    },
    
];

export default skills;