import React from 'react'
import Top from '../sections/Top';
import About from '../sections/About';
import Testimonials from '../sections/Testimonials';


const Home = ({ setMouseShadowDisabled, mouseShadowDisabled }) => {
    return (
        <div>
            <Top setMouseShadowDisabled={setMouseShadowDisabled} />
            <About setMouseShadowDisabled={setMouseShadowDisabled} />
            <Testimonials setMouseShadowDisabled={setMouseShadowDisabled} />
        </div>
    )
}

export default Home