import React from 'react'
import ProjectsGrid from '../sections/ProjectsGrid'
import Websites from '../sections/Websites'

const Projects = ({ setMouseShadowDisabled, mouseShadowDisabled }) => {
    return (
        <div className='pt-28 lg:pt-60'>
            <Websites setMouseShadowDisabled={setMouseShadowDisabled} />
            <ProjectsGrid setMouseShadowDisabled={setMouseShadowDisabled} />
        </div>
    )
}

export default Projects